<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <Value :field="field" />
        </el-col>
        <el-col :span="12">
          <div class="form-group">
            <Tag :field="field" />
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import TitleAndDescription from "./TitleAndDescription";
import Value from "./Value"
import Tag from "./Tag"

export default {
  components: {
    TitleAndDescription,
    Value,
    Tag
  },
  props: ["field"],
  data() {
    return {
      validations: []
    };
  }
};
</script>

<style lang="scss">
</style>