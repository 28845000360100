<template>
  <div>
    <el-form label-position="right">
      <Content :field="field" />

    
    </el-form>
  </div>
</template>

<script>
// import Content from "./Content";
export default {
  name:"templates-formComponents-Heading",
  components: {
  
    "Content":()=>import("./Content.vue")
  },
  props: ["field"],
  data() {
    return {};
  },
    mounted() {
     this.field['filled_by'] = 'SENDER';
  }
};
</script>

<style lang="scss">
</style>