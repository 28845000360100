<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <div class="form-group">
        <Tag :field="field" />
      </div>
      <el-divider></el-divider>
      <div class="text-center">
        <el-form :inline="true">
          <el-form-item label="List Items Data" class="label-with-asterisk">
            <div class="input-in-list">
              <el-input
              v-model="newElement.name"
              placeholder="Enter Name"
            ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="newElement.value"
              placeholder="Enter Value"
              type="text"
              @input="handleInput"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              @click="addElement"
              type="primary"
              icon="el-icon-plus"
              class="f-weight-600"
            >
              ADD
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="vue-data-table-mini mb-2">
        <el-table border :data="field.list_data">
          <el-table-column prop="name" label="Name" min-width="250">
            <template slot-scope="scope">
              <el-input v-model="field.list_data[scope.$index].name"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="value" label="Value" width="250">
            <template slot-scope="scope">
              <el-input
                v-model="field.list_data[scope.$index].value"
                type="text"
                @input="handleNumericInput(scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="value"
            label="Actions"
            width="100"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                @click="deleteValue(scope.$index)"
                icon="el-icon-delete"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>
  </div>
</template>

<script>
import TitleAndDescription from "./TitleAndDescription";
import Tag from "./Tag";

export default {
  components: {
    TitleAndDescription,
    Tag,
  },
  props: ["field"],
  data() {
    return {
      newElement: {
        name: "",
        value: "",
      },
    };
  },
  mounted() {
    if (!this.field.list_data) {
      this.field.list_data = [];
    }
  },
  methods: {
    addElement() {
      // let duplicateListNames=  (this.field.list_data).find(
      //   ( e )=> {return e}
      // ),
      let duplicates = this.field.list_data.filter(e => e.name == this.newElement.name && e.value == this.newElement.value)
      if(this.newElement.name == "" || !this.newElement.name){
         this.$message.error("Please add name!")
      }
      else if(this.newElement.value == "" || !this.newElement.value){
        this.$message.error("Please add value!")
      }
     else if (duplicates.length > 0) {
      this.$message.error("The values already exist!")
    }
      else{
        this.field.list_data.push(this.newElement);
      this.newElement = {
        name: "",
        value: "",
      };
      }
     
    },
    deleteValue(index) {
      this.field.list_data.splice(index, 1);
    },
    handleInput() {
      this.newElement.value = this.newElement.value.replace(/[^0-9]/g, '');
     
    },
    handleNumericInput(index) {
    this.field.list_data[index].value = this.field.list_data[index].value.replace(/[^0-9]/g, '');
    if (this.field.list_data[index].value === '') {
      this.field.list_data[index].value = '';
    }
  },
  },
};
</script>

<style lang="scss">
.input-in-list{
  margin-left: 10px;
}
.label-with-asterisk {
  position: relative;
}

.label-with-asterisk::after {
  content: "*";
  position: absolute;
  top: 50%;
  font-size: 20px;
  right: 65%;
  transform: translateY(-50%);
  margin-left: 5px; 
  color: red;

  @media (max-width:767px){
    top: 30%;
    right: 30% !important;
  }
}
</style>