<template>
  <div>
    <div class="form-group">
      <el-form-item label="Value">
        <span style="color:red; font-size: 20px;">* </span> 
        <el-input-number
          v-model="field.value"
          v-if="field.input_type == 'NUMBER'"
          :controls="false"
          class="value-col"
        ></el-input-number>
        <el-input
          v-else-if="field.input_type == 'SINGLE_LINE_TEXT'"
          v-model="field.value"
          type="text"
          maxlength="64"
        ></el-input>
        <el-input
          v-else-if="field.input_type != 'NUMBER' && field.input_type != 'SINGLE_LINE_TEXT'"
          v-model="field.value"
          type="text"
        ></el-input>
        <el-input v-else v-model="field.value" type="number"></el-input>
        <FormError error-key="value" />
      </el-form-item>
    </div>
  </div>
</template>

<script>
import FormError from "@/components/form/FormError";
export default {
  components: { FormError },
  props: ["field"],
  data() {
    return {};
  },
  mountd() {},
};
</script>

<style lang="scss">
@media (max-width: 767px) {
  .value-col {
    width: 100%; 
    padding: 0 15px; 
  }
}
</style>
