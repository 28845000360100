<template>
    <div>
      <el-form label-position="right">
        <title-and-description :field="field"/>
        <el-row :gutter="30" v-if="getIsMobile">
          <el-col :span="12">
            <el-form-item label="Value">
            <span style="color:red; font-size: 20px;">* </span> 
            <el-date-picker
                        prefix-icon="el-icon-date"
                          :format="'MM-dd-yyyy HH:mm:ss'"
                          v-model="field.value"
                          type="datetimerange"
                          :picker-options="pickerOptions"
                          range-separator="To"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          popper-class="my-popover"
                        />
         
          <FormError error-key="value" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="form-group">
              <Tag :field="field"/>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="30" type="flex" v-if="!getIsMobile">
          <el-col :span="12">
            <el-form-item label="Value">
            <span style="color:red; font-size: 20px;">* </span> 
            <el-date-picker
                        prefix-icon="el-icon-date"
                          :format="'MM-dd-yyyy HH:mm:ss'"
                          v-model="field.value"
                          type="datetimerange"
                          :picker-options="pickerOptions"
                          range-separator="To"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          popper-class="my-popover"
                        />
         
          <FormError error-key="value" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="form-group">
              <Tag :field="field"/>
            </div>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="50">
          <el-col :span="12">
            <div class="form-group">
              <el-form-item label="Validations">
                <el-date-picker
                  type="date"
                  placeholder="Min date"
                  v-model="field.validations.min"
                  style="width: 100%;"
                  format="yyyy-dd-MM"
                ></el-date-picker>
                <el-date-picker
                  type="date"
                  placeholder="Max date"
                  v-model="field.validations.max"
                  style="width: 100%;"
                  format="yyyy-dd-MM"
                ></el-date-picker>
              </el-form-item>
            </div>
          </el-col>
        </el-row>-->
  
      </el-form>
    </div>
  </template>
  
  <script>
  import FormError from "@/components/form/FormError";
  import * as moment from "moment"
  import Tag from "./Tag"
  import TitleAndDescription from "./TitleAndDescription";
  import { bus } from "../../../main";
  import { mapGetters} from "vuex";

  
  export default {
    components: {
      FormError,
      TitleAndDescription,
      Tag
  
    },
    props: ["field"],
    data() {
      return {
        validations: [],
        date: "",
        pickerOptions: {
        shortcuts: [
          // {
          //   text: 'Today',
          //   onClick() {
          //     bus.$emit("date_range",'TODAY')
          //   }
          // },
          {
            text: "24 Hours",
            onClick() {
              bus.$emit("date_range", "TOMARROW");
            },
          },
          {
            text: "Weekly",
            onClick() {
              bus.$emit("date_range", "WEEK");
            },
          },
          {
            text: "Bi-weekly",
            onClick() {
              bus.$emit("date_range", "BIWEEKLY");
            },
          },
          {
            text: "Monthly",
            onClick() {
              bus.$emit("date_range", "MONTHLY");
            },
          },
          {
            text: "Quarterly",
            onClick() {
              bus.$emit("date_range", "QUARTERLY");
            },
          },
          {
            text: "Half-yearly",
            onClick() {
              bus.$emit("date_range", "HALFYEARLY");
            },
          },
          {
            text: "Yearly",
            onClick() {
              bus.$emit("date_range", "YEARLY");
            },
          },
        ],
      },
      };
    },
    computed: {
      ...mapGetters("navigationOpen", ["getIsMobile"])
    },
    methods: {
      onSelectDate() {
        this.field.value = moment(this.date).utc()
        
      }
    },
    created() {
   
    bus.$on("date_range", (data) => {
      // if(data==='TODAY')
      // {
      //     const end = new Date()
      //     const start = new Date()
      //     this.formBuilder.settings.formbuilder_date_range=[start, end]
      // }
      if (data === "TOMARROW") {
        const end = new Date();
        const start = new Date();
        var newDate = moment(end).add(1, "days");
        this.field.value = [start, newDate];
      }
      if (data === "WEEK") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(7, "days");
        this.field.value = [start, newDate];
      }
      if (data === "BIWEEKLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(15, "days");
        this.field.value = [start, newDate];
      }
      if (data === "MONTHLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(1, "months");
        this.field.value = [start, newDate];
      }
      if (data === "QUARTERLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(3, "months");
        this.field.value= [start, newDate];
      }
      if (data === "HALFYEARLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(6, "months");
        this.field.value = [start, newDate];
      }
      if (data === "YEARLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(1, "years");
        this.field.value = [start, newDate];
      }
    });
  },
  };
  </script>
  
<style lang="scss" scoped>
  @media (max-width: 767px){
  .el-range-editor.el-input__inner {
    width: 300px !important;
  }
  ::v-deep .el-date-editor .el-range-separator {
    width: 8% !important;
  }
}
</style>