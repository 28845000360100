<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field"/>
      <el-row :gutter="30" type="flex" v-if="!getIsMobile">
        <el-col :span="12">
          <el-form-item label="Value">
            <span style="color:red; font-size: 20px;">*</span>   
              <el-time-picker
              :format="'HH:mm:ss'"
              v-model="field.value"
               is-range
              range-separator="To"
              start-placeholder= "Start Time"
              prefix-icon="el-icon-time"
              end-placeholder="End time"
              type="timerange"
              popper-class="my-popover"
              />
       
        <FormError error-key="value" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div class="form-group">
            <Tag :field="field"/>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="30" v-if="getIsMobile">
        <el-col :span="12">
          <el-form-item label="Value">
            <span style="color:red; font-size: 20px;">*</span>   
              <el-time-picker
              :format="'HH:mm:ss'"
              v-model="field.value"
               is-range
              range-separator="To"
              start-placeholder= "Start Time"
              prefix-icon="el-icon-time"
              end-placeholder="End time"
              type="timerange"
              popper-class="my-popover"
              />
       
        <FormError error-key="value" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="form-group">
            <Tag :field="field"/>
          </div>
        </el-col>
      </el-row>
      

    </el-form>
  </div>
</template>

<script>
  
import FormError from "@/components/form/FormError";
import * as moment from "moment"
import Tag from "./Tag"
import TitleAndDescription from "./TitleAndDescription";
import { mapGetters } from "vuex";


export default {
  components: {
    FormError,
    TitleAndDescription,
    Tag

  },
  props: ["field"],
  data() {
    return {
      validations: [],
    };
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"])
  },
  methods: {
    onSelectDate() {
      this.field.value = moment(this.date).utc()
      
    }
  },
 
};
</script>

<style lang="scss" scoped>
  @media (max-width: 767px){
  .el-range-editor.el-input__inner {
    width: 300px !important;
  }
}
::v-deep .el-date-editor .el-range-separator {
  width: 8% !important;
}
</style>