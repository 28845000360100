<template>
  <p v-if="hasCriticalError" class="error">{{ message }}</p>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  /**
   * Components Name.
   *
   * @type {String}
   */
  name: "form-CriticalErrorMessage",

  /**
   * Components computed properties.
   *
   * @type {Object}
   */
  computed: {
    /**
     * Determine if we have general critical error message.
     * @return {boolean}
     */
    hasCriticalError() {
      return !!this.getError
    },

    /**
     * Get error message.
     *
     * @return {String}
     */
    message() {
      return this.getError
    },

    ...mapGetters('errors', ['getError'])
  }
}
</script>

<style scoped>

</style>