<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row :gutter="30" v-if="getIsMobile">
        <el-col :span="24">
          <el-form-item>
            <el-checkbox v-model="field.is_fixed" class="mt-2">Fixed Time</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="10" v-if="!field.is_fixed">
          <el-form-item label="Value">
            <span style="color:red; font-size: 20px;">*</span>  
            <el-time-picker
              v-if="!isView && !isList"
              prefix-icon="el-icon-time"
              v-model="field.value"
              :picker-options="{ format: 'HH:mm:ss' }"
              :value-format="'HH:mm:ss'"
              class="eb-ctm-input-time"
              placeholder="Select Time"
            >
            </el-time-picker>
            <FormError error-key="value" />
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="field.is_fixed">
          <el-form-item label="Time Range" class="mt-10">
            <el-time-picker
              is-range
              v-model="field.properties.time_range"
              range-separator="To"
              start-placeholder="Start time"
              end-placeholder="End time"
            >
            </el-time-picker>
          </el-form-item>
          <!-- Step Field -->
          <el-form-item label="Step">
            <el-select
              placeholder="Step time"
              v-model="field.properties.step_time"
              filterable
              style="width: 100%"
            >
              <!-- Options for Step Time -->
              <el-option
                v-for="t in 11"
                :key="t + 'start'"
                :value="t * 5 + ':00'"
                :label="t * 5 + ' minutes'"
              >
                {{ t * 5 + ' minutes' }}
              </el-option>
              <el-option
                v-for="t in 7"
                :key="t + 'start_hours'"
                :value="t * 60 + ':00'"
                :label="t * 60 + ' minutes'"
              >
                {{ t * 60 + ' minutes' }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="form-group">
            <Tag :field="field" />
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="30" type="flex" v-if="!getIsMobile">
        <el-col :span="6">
          <el-form-item >
            <el-checkbox v-model="field.is_fixed" class="mt-2">Fixed Time</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="10" v-if="!field.is_fixed">
          <el-form-item label="Value">
            <span style="color:red; font-size: 20px;">*</span>  
            <el-time-picker
              v-if="!isView && !isList"
              prefix-icon="el-icon-time"
              v-model="field.value"
              :picker-options="{ format: 'HH:mm:ss' }"
              :value-format="'HH:mm:ss'"
              class="eb-ctm-input-time"
              placeholder="Select Time"
            >
            </el-time-picker>
            <FormError error-key="value" />
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="field.is_fixed">
          <el-form-item label="Time Range" class="mt-10">
            <el-time-picker
              is-range
              v-model="field.properties.time_range"
              range-separator="To"
              start-placeholder="Start time"
              end-placeholder="End time"
            >
            </el-time-picker>
          </el-form-item>
          <!-- Step Field -->
          <el-form-item label="Step">
            <el-select
              placeholder="Step time"
              v-model="field.properties.step_time"
              filterable
              style="width: 100%"
            >
              <!-- Options for Step Time -->
              <el-option
                v-for="t in 11"
                :key="t + 'start'"
                :value="t * 5 + ':00'"
                :label="t * 5 + ' minutes'"
              >
                {{ t * 5 + ' minutes' }}
              </el-option>
              <el-option
                v-for="t in 7"
                :key="t + 'start_hours'"
                :value="t * 60 + ':00'"
                :label="t * 60 + ' minutes'"
              >
                {{ t * 60 + ' minutes' }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div class="form-group">
            <Tag :field="field" />
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import FormError from "@/components/form/FormError";
import * as moment from "moment";
import Tag from "./Tag";
import TitleAndDescription from "./TitleAndDescription";
import { mapGetters } from "vuex";

export default {
  components: {
    FormError,
    TitleAndDescription,
    Tag,
  },
  props: ["field"],
  mounted(){
    let fieldProperties = this.field?.properties;
    if(!(fieldProperties && fieldProperties.length)) {
      this.$set(this.field, "properties", {
        time_range: [
          new Date(2016, 9, 10, 0, 0),
          new Date(2016, 9, 10, 23, 59, 59),
        ],
        step_time: "30",
      });
    }
  },
  data() {
    return {
      validations:[],
      date:"",
      isView: false,
      isList: false,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
  },
  methods: {
    onSelectDate() {
      this.field.value = moment(this.date).utc();
      
    },
  },
};
</script>

<style lang="scss">
</style>
