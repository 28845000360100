<template>
  <div>
    <el-form>
      <el-row :gutter="30">
        <el-col>
          <title-and-description :field="field" />
        </el-col>
        <el-col :span="12">
          <div class="form-group">
            <el-form-item class="content">
              HTML Content
              <span style="color:red; font-size: 20px;">* </span>
              <el-input type="textarea" v-model="field.value" placeholder="Enter content"></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="form-group">
            <Tag :field="field" />
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import TitleAndDescription from "./TitleAndDescription"
import Tag from "./Tag"

export default {
  props: ["field"],
  components: {
    TitleAndDescription,
    Tag
  },
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .content{
    width:135px;
  }
}
</style>