<template>
    <div>
      <el-form label-position="right">
        <title-and-description :field="field" />
        <el-row :gutter="30" type="flex">
          <el-col :span="12">
            <el-form-item label="Value">
              <span style="color:red; font-size: 20px;">* </span>
              <el-select
                popper-class="my-popover"
                v-model="field.value"
                collapse-tags
                clearable
                type="weekdays"
              >
                <el-option
                  v-for="(option, index) in options"
                  :value="option.value"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
              <FormError error-key="value" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="form-group">
              <Tag :field="field" />
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </template>
  
  <script>
        import FormError from "@/components/form/FormError";
        import Tag from "./Tag";
        import TitleAndDescription from "./TitleAndDescription";
        
  export default {
    components: {
      FormError,
      TitleAndDescription,
      Tag
    },
    props: ["field"],
    data() {
      return {
        options: [
          { value: "MON", name: "Monday" },
          { value: "TUE", name: "Tuesday" },
          { value: "WED", name: "Wednesday" },
          { value: "THU", name: "Thursday" },
          { value: "FRI", name: "Friday" },
          { value: "SAT", name: "Saturday" },
          { value: "SUN", name: "Sunday" }
        ]
      };
    }
  };
  </script>
  
  <style lang="scss">
  </style>
  