<template>
    <div>
      <el-form label-position="right">
        <title-and-description :field="field"/>
        <el-row :gutter="30" type="flex">
          <el-col :span="12">
            <el-form-item label="Value">
              <span style="color:red; font-size: 20px;">* </span>
                <!-- <el-date-picker
            type="datetime"
             prefix-icon="el-icon-date"
            v-model="field.value"
            placeholder="Pick a day and time"
            ></el-date-picker> -->

            <el-date-picker
          prefix-icon="el-icon-date"
          v-model="field.value"
          type="datetime"
          :format="'MM-dd-yyyy HH:mm:ss'"    
        >
        </el-date-picker>
      
             
              <FormError error-key="value" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="form-group">
              <Tag :field="field"/>
            </div>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="50">
          <el-col :span="12">
            <div class="form-group">
              <el-form-item label="Validations">
                <el-date-picker
                  type="date"
                  placeholder="Min date"
                  v-model="field.validations.min"
                  style="width: 100%;"
                  format="yyyy-dd-MM"
                ></el-date-picker>
                <el-date-picker
                  type="date"
                  placeholder="Max date"
                  v-model="field.validations.max"
                  style="width: 100%;"
                  format="yyyy-dd-MM"
                ></el-date-picker>
              </el-form-item>
            </div>
          </el-col>
        </el-row>-->
  
      </el-form>
    </div>
  </template>
  
  <script>
  import FormError from "@/components/form/FormError";
  import * as moment from "moment"
  import Tag from "./Tag"
  import TitleAndDescription from "./TitleAndDescription";
  
  export default {
    components: {
      FormError,
      TitleAndDescription,
      Tag
  
    },
    props: ["field"],
    data() {
      return {
        validations: [],
        date: ""
      };
    },
    methods: {
      onSelectDate() {
        this.field.value = moment(this.date).utc()
        
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @media (max-width:768px){
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 150px;
  }
}

  </style>