<template>
  <div>
    <NewGlobalVariable></NewGlobalVariable>
  </div>
</template>

<script>
import NewGlobalVariable from "@/components/globalVariables/NewGlobalVariable";
export default {
  components: {
    NewGlobalVariable
  }
};
</script>

<style>
</style>