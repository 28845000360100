<template>
  <div>
    <el-form>
      <el-row :gutter="50">
        <el-col :span="24">
          <div class="form-group">
            <el-form-item label="Content">
              <el-input v-model="field.content" placeholder="Enter content" maxlength="200"></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["field"],
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style lang="scss">
</style>