<template>
  <div class="tool-tip-inner">
    <div class="form-header">
      <div class="icon-block">
        <span>
          <img src="@/assets/img/icons/model-category.svg" alt />
        </span>
        <div class="heading">
          <h3>{{ isEdit ? "Edit" : "Add" }} Tag</h3>
        </div>
      </div>
      <span class="close-icon" @click="closeCategory">
        <img
          src="@/assets/img/icons/tooltip-close-icon.svg"
          alt="icon"
          class="img-fluid"
        />
      </span>
    </div>
    <div class="content">
      <el-form v-model="tagForm">
        <div class="form-group">
          <el-input placeholder="Name" v-model="tagForm.name"></el-input>
          <FormError error-key="name" />
          <CriticalErrorMessage />
        </div>
        <div class="form-group submit-btn-group">
          <button
            type="button"
            class="btn submit-btn"
            @click.prevent="createTag"
            :disabled="disableButton"
          >
            submit
          </button>
        </div>
      </el-form>
      <span class="down-shape"></span>
    </div>
  </div>
</template>

<script>
import CriticalErrorMessage from "@/components/form/CriticalErrorMessage";
import FormError from "@/components/form/FormError";
import { mapGetters } from "vuex";

export default {
  /**
   * Components Name.
   *
   * @type {String}
   */
  name: "AddCategory",

  /**
   * Components computed properties.
   *
   * @type {Object}
   */
  components: { CriticalErrorMessage, FormError },

  /**
   * Components properties.
   *
   * @type {Object}
   */
  props: ["isEdit", "field"],

  /**
   * Components initial properties.
   *
   * @type {Object}
   */
  data() {
    return {
      tagForm: {
        name: "",
      },
      errors: {},
      disableButton: false,
    };
  },

  /**
   * Components computed properties.
   *
   * @type {Object}
   */
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    ...mapGetters("globalVariables", [
      "getGlobalVariableTagAddStatus",
      "getAllGlobalVariableTags",
      "getGlobalVariableErrors",
      "getNewTag",
    ]),
  },

  /**
   * Components mounted life hook.
   *
   * @type {Object}
   */
  async mounted() {
    this.$store.commit("errors/reset");
    if (this.isEdit) {
      this.tagForm.name = this.field.name;
    }
  },
  /**
   * Components methods.
   *
   * @type {Object}
   */
  methods: {
    async createTag() {
      this.disableButton = true;
      let params = {
        name: this.tagForm.name,
      };
      await this.$store.dispatch(
        "globalVariables/addGlobalVariableTag",
        params
      );
      if (this.getGlobalVariableTagAddStatus) {
        await this.$store.dispatch("globalVariables/fetchGlobalVariableTags");
        if (this.getNewTag) {
          this.$emit("tag-added", this.getNewTag);
        }
      } else {
        this.errors = this.getGlobalVariableErrors;
      }
      this.disableButton = false;
    },

    // async updateCategory() {
    //   this.disableButton = true;
    //   let params = {
    //     name: this.tagForm.name,
    //     id: this.selectedCategory._id
    //   };
    //   await this.$store.dispatch("documentCategories/updateCategory", params);

    //   if (this.getCategoryUpdateStatus) {
    //     this.$emit("category-updated");
    //   } else {
    //     this.errors = this.getCategoryErrors;
    //   }
    //   this.disableButton = false;
    // },
    clearFormData() {
      this.tagForm = {
        name: "",
      };
      this.errors = {};
      this.$store.dispatch("globalVariables/unSetNewTag");
    },
    closeCategory() {
      this.$emit("closed");
    },
  },
  beforeDestroy() {
    this.clearFormData();
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  cursor: pointer;
}
</style>