<template>
  <div>
    <el-row :gutter="30">
      <el-col :span="12">
        <div class="form-group">
          <el-form-item label="Field Title">
            <span style="color:red; font-size: 20px;">*</span>  
            <el-input
              type="text"
              maxlength="200"
              v-model="field.label"
              placeholder="Enter Field Title"
            ></el-input>
             <p  v-if="field.label && !field.label.length" class="error"> *Title is required*</p>
  
             <FormError error-key="label" />
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="form-group">
          <el-form-item label="Description">
            <el-input
              type="textarea"
              v-model="field.description"
              placeholder="Enter Description Here"
            ></el-input>
            <FormError error-key="description" />
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FormError from "@/components/form/FormError";
export default {
  components: { FormError },
  props: ["field"],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>