<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row :gutter="30" type="flex">
        <!-- <el-col :span="12">
          <placeholder :field="field" />
        </el-col>-->
        <el-col :span="12">
          <Value :field="field" />
        </el-col>
        <el-col :span="12">
          <div class="form-group">
            <Tag :field="field" />
          </div>
        </el-col>
      </el-row>
      <!-- <is-field-required :field="field" /> -->
    </el-form>
  </div>
</template>

<script>
export default {
  components: {
    TitleAndDescription: () =>
      import("@/components/templates/formComponents/TitleAndDescription"),
    // IsFieldRequired,
    // FieldFilledBy,
    // Placesholder,
    Value: () => import("./Value.vue"),
    Tag: () => import("./Tag.vue"),
  },
  props: ["field"],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>