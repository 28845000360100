<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row :gutter="30">
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="12">
          <div class="mt-2">
            <el-row :gutter="10">
              <el-col :span="10">Field Required!</el-col>
              <el-col :span="14">
                <div class="form-group">
                  <el-radio v-model="field.required" label="1">Yes</el-radio>
                  <el-radio v-model="field.required" label="2">No</el-radio>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import TitleAndDescription from "./TitleAndDescription";
import FieldFilledBy from "./FieldFilledBy";

export default {
  name: "YesOrNo",
  props: ["field"],
  components: { TitleAndDescription, FieldFilledBy },
  data() {
    return {
      validations: []
    };
  }
};
</script>

<style lang="scss">
</style>
