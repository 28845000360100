<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <div class="rate-container">
        <el-rate
          v-model="field.value"
          :allow-half="true"
          :max="5"
          :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
          @change="applyFormRules"
        >
        </el-rate>
        <span class="required">*</span>
      </div>
    </el-form>
  </div>
</template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    name:"templates-formComponents-StarRating",
    components: {
       "TitleAndDescription":()=>import("./TitleAndDescription"),
    },
    
    props: ["field"],
    computed: {
      ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
      allGlobalVariables() {
        return this.getAllGlobalVariables
          ? this.getAllGlobalVariables.data || []
          : [];
      }
    },
    mounted() {
      this.fetchGlobalVaribales();
    },
    data() {
      return {};
    },
    methods: {
        applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "SINGLE_LINE_TEXT") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },
        readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          // if (this.data.styles.labelStyle == "right") {
          //   this.isDefalutPos = false;
          // }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
      async fetchGlobalVaribales() {
       
        let params = {
          get_all: true,
          input_type: this.field.input_type
        };
  
        await this.$store.dispatch(
          "globalVariables/fetchGlobalVariables",
          params
        );
      },
    }
  };
  </script>
  
  <style lang="scss">
.rate-container {
  display: flex;
  align-items: center;
}

.required {
  color: red;
  font-size: 20px;
  margin-left: 5px;
}
  </style>